@import '~antd/dist/antd.less';
@import 'pages/LandingPage.less';

@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

amplify-authenticator {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  height: 60vh;
}

:root {
  --amplify-font-family: 'Montserrat', sans-serif;
  --amplify-primary-color: #ff4d4f;
  --amplify-primary-tint: #ff6163;
  --amplify-primary-shade: #d43f41;
}

.row {
  display: flex;
  flex-direction: row;
}

.column {
  display: flex;
  flex-direction: column;
}

.flex-right {
  display: flex;
  justify-content: flex-end;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
}

.red {
  color: @primary-color
}

.black {
  color: @black
}

.white {
  color: @white;
}

.bold {
  font-weight: bold;
}

.unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.mb0 {
  margin-bottom: 0;
}

@primary-color: #ff4d4f;