@primary-color: #ff4d4f;

.landing-layout {
  background-color: #000;
  color: #fff;
}

.landing-content {
  max-width: 1024px;
  @media screen and (min-width: 1023px) {
    margin: 0 auto;
  }
  padding: 2rem 1rem 4rem;
}

.landing-section-header {
  align-items: baseline;
}

.landing-h2 {
  font-size: 2.2rem;
  line-height: 2.5rem;
  color: #fff;
  font-weight: bold;
  text-align: center;
  @media screen and (min-width: 1023px) {
    font-size: 2.5rem;
    line-height: 2.8rem;
  }
}

.landing-h3 {
  font-size: 1.4rem;
  line-height: 2rem;
  color: #fff;
  font-weight: bold;
  text-align: center;
  @media screen and (min-width: 1023px) {
    font-size: 1.7rem;
    line-height: 2.5rem;
  }
}

.landing-p {
  font-size: 1.4rem;
  color: #fff;
}

.landing-bracket {
  font-size: 130%;
  margin: 0 1rem;
}

.landing-label {
  display: inline-block;
}

.landing-input {
  background-color: #fff;
  border-radius: 5px;
  color: #000;
  font-weight: bold;
  overflow: auto;
  padding-left: 5px;
  clear: left;
  width: 300px;

  &:focus {
    outline: none;
  }
}

.landing-carousel-outer {
  height: 550px;
  @media screen and (min-width: 767px) {
    height: 500px;
  }
}

.landing-carousel-inner {
  border: 1px solid @primary-color;
  border-radius: 4px;
  padding: 1rem;
  height: 500px;
  @media screen and (min-width: 767px) {
    height: 450px;
  }
}

.landing-vertical-spacing {
  margin: 2.2rem 0;
  @media screen and (min-width: 767px) {
    margin: 2.5rem 0;
  }
  @media screen and (min-width: 1023px) {
    margin: 3.0rem 0;
  }
}

.landing-header {
  background-color: transparent;
  display: flex;
  justify-content: space-between;
  position: absolute;
  z-index: 1;
  align-items: center;
  width: 100%;
  padding: 1rem;
}

.landing-heroContainer {
  margin: 0 auto;
  position: relative;
}

.landing-heroTextContainer {
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  z-index: 1;
}

.landing-hero-title {
  font-size: 4.5rem;
  margin-bottom: 3rem;
  @media screen and (min-width: 767px) {
    font-size: 6.5rem;
    margin-bottom: 4rem;
  }
  @media screen and (min-width: 1023px) {
    font-size: 8.5rem;
    margin-bottom: 5rem;
  }
  color: #fff;
  line-height: 0;
  text-align: center;
}

.landing-hero-subtitle {
  font-size: 1.4rem;
  line-height: 2rem;
  @media screen and (min-width: 1023px) {
    font-size: 1.8rem;
    line-height: 2.5rem;
  }
}

.landing-btn {
  background-color: @primary-color;
  color: #fff;
  font-weight: bold;
  font-size: 1.1rem;
  padding: 10px 10px;
  border-width: 0;
  align-self: flex-end;
  border-radius: 5px;
  cursor: pointer;
  width: 150px;
}

.landing-btn-container {
  width: 200px;
  justify-content: space-between;
  align-self: flex-end;
}

.landing-cta {
  background-color: @primary-color;
  padding: 1rem;
  border-radius: 1rem;
}